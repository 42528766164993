import * as React from 'react';
import RadioGroup from 'antd/es/radio/group';
import './RadioGroupInput.scss';
import { Form, Radio } from 'antd';
import { InfoIcon } from 'storybook-components/InfoIcon/InfoIcon';
import InputProps from '../InputProps';

interface radioProps extends InputProps<any> {
  options: {
    value: any
    label: string
    description?: React.ReactNode,
    infoIconText?: string
    disabled?: boolean
    autoFocus?: boolean
  }[],
justifyContent?: 'unset' | 'space-between',
}

export default function RadioGroupInput(props: radioProps): JSX.Element {
  const {
    className, label, required, inputClassName, disabled, validationState, validationMessage, options, onChange, justifyContent,
  } = props;
  const { value } = props;

  const radioGroup = () => (
    <RadioGroup
      className={`RadioGroup ${className} justify-content-${justifyContent}`}
      onChange={event => onChange(event.target.value)}
      value={value}
      disabled={disabled || false}
    >
      {options.map((elem) => {
        const radioSelect = () => (
          <div className="radio-group-option">
            <Radio
              {...elem}
              value={elem.value}
              key={elem.value}
              className={`${inputClassName}`}
              disabled={elem.disabled || false}
            >
              {elem.label}
              {elem.infoIconText ? (<InfoIcon popupText={elem.infoIconText} placement="right" size="large" />) : ''}
            </Radio>
            {elem.description && <p className="radio-description">{elem.description}</p>}
          </div>
        );

        return radioSelect();
      })
      }
    </RadioGroup>
  );

  return (
    <div className={`RadioGroupInput ${className}`}>
      <Form.Item
        label={`${label}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        {radioGroup()}
      </Form.Item>
    </div>
  );
}

RadioGroupInput.defaultProps = {
  className: '',
  inputClassName: '',
  disabled: false,
  required: false,
  tooltipText: '',
  label: '',
  justifyContent: 'unset',
};
