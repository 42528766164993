export const translations: any = {
  wkaList: {
    actions: {
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
      description: {
        en: 'Are you sure you want to delete this report?',
        de: 'Sind Sie sicher, dass Sie diese Abrechnung löschen möchten?',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
    filter: {
      title: {
        en: 'Title',
        de: 'Titel',
      },
      property: {
        en: 'Property',
        de: 'Objekt',
      },
      startDate: {
        en: 'Start date',
        de: 'Startdatum',
      },
      endDate: {
        en: 'End date',
        de: 'Enddatum',
      },
      propertyAdministrationTypes: {
        en: 'Administration type',
        de: 'Verwaltungsart',
      },
      administrationTypeValues: {
        WEG: {
          en: 'Wohnungseigentum (WEG)',
          de: 'Wohnungseigentum (WEG)',
        },
        MV: {
          en: 'Mietverwaltung (MV)',
          de: 'Mietverwaltung (MV)',
        },
        SEV_MV: {
          en: 'SEV without WEG connection',
          de: 'SEV ohne WEG-Verbindung',
        },
      },
      administrator: {
        en: 'Administrator',
        de: 'Objektbetreuer',
      },
      accountant: {
        en: 'Accountant',
        de: 'Objektbuchhalter',
      },
      createdBy: {
        en: 'Created by',
        de: 'Erstellt von',
      },
    },
    loadFailed: {
      message: {
        en: 'Failed to load WKA',
        de: 'WKA konnte nicht geladen werden',
      },
    },
    deleteFailed: {
      message: {
        en: 'Failed to delete WKA',
        de: 'Fehler beim Löschen von WKA',
      },
    },
    wkaStatuses: {
      OPEN: {
        en: 'Open',
        de: 'Ablesung offen',
      },
      READERS_INFORMED: {
        en: 'Readers informed',
        de: 'Ableser informiert',
      },
      READERS_REMINDED: {
        en: 'Readers reminded',
        de: 'Ableser erinnert',
      },
      READING_COMPLETE: {
        en: 'Reading Complete',
        de: 'Ablesung vollständig',
      },
      RESPONSE_OPEN: {
        en: 'Response open',
        de: 'Rückmeldung offen',
      },
      RESPONSE_DONE: {
        en: 'Response done',
        de: 'Rückmeldung erfolgt',
      },
    },
    tableColumns: {
      title: {
        en: 'Title',
        de: 'Titel',
      },
      status: {
        en: 'Status',
        de: 'Status',
      },
      property: {
        en: 'Property',
        de: 'Objekt',
      },
      dateRange: {
        en: 'Date range',
        de: 'Zeitraum',
      },
      year: {
        en: 'Year',
        de: 'Jahr',
      },
      buildings: {
        en: 'Nr. of buildings',
        de: 'Gebäude',
      },
      meters: {
        en: 'Nr. of meters',
        de: 'Zähler',
      },
      readingCompany: {
        en: 'Reading company',
        de: 'Ableser',
      },
      supplierCompany: {
        en: 'Supplier company',
        de: 'Versorger',
      },
      costCalculator: {
        en: 'Heat cost calculator',
        de: 'Abrechner',
      },
      totalCosts: {
        en: 'Total costs',
        de: 'Gesamtkosten',
      },
      administrator: {
        en: 'Administrator',
        de: 'Objektbetreuer',
      },
      accountant: {
        en: 'Accountant',
        de: 'Objektbuchhalter',
      },
      created: {
        en: 'Created on',
        de: 'Erstellt am',
      },
      createdBy: {
        en: 'Created by',
        de: 'Erstellt von',
      },
      state: {
        en: 'State',
        de: 'Status',
      },
    },
    processSteps: {
      registration: {
        en: 'Registration',
        de: 'Erfassung',
      },
      transmission: {
        en: 'Transmission',
        de: 'Übermittlung',
      },
      overyearlyAccount: {
        en: 'Overyearly account',
        de: 'Jahresabrechnung',
      },
      response: {
        en: 'Response',
        de: 'Rückmeldung',
      },
      all: {
        en: 'All',
        de: 'Alle',
      },
    },
    tableActions: {
      enterCosts: {
        en: 'Enter costs',
        de: 'Kosten manuell eingeben',
      },
      splitPdf: {
        en: 'Assign document',
        de: 'Dokument zuweisen',
      },
    },
    sent: {
      en: 'Sent',
      de: 'Gesendet',
    },
    notSent: {
      en: 'Not sent',
      de: 'Nicht versendet',
    },
  },
  notifications: {
    uploadError: {
      message: {
        en: 'Upload failed',
        de: 'Upload fehlgeschlagen',
      },
    },
    uploadSuccess: {
      message: {
        en: 'files were uccessfully uploaded.',
        de: 'Dateien werden erfolgreich hochgeladen',
      },
    },
    downloadError: {
      message: {
        en: 'Download failed',
        de: 'Download fehlgeschlagen',
      },
    },
    existsError: {
      en: 'Already exists for the selected year',
      de: 'WKA ist bereits vorhanden für dieses Jahr ',
    },
    createError: {
      en: 'Could not create',
      de: 'WKA konnte nicht ertellt werden',
    },
  },
  fileUploadModal: {
    title: {
      en: 'Allocate cost statements',
      de: 'Abrechnungen zuweisen',
    },
    upload: {
      en: 'Upload',
      de: 'Hochladen',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    body: {
      en: 'By uploading the overall cost statement (PDF), you will be able to assign the individual cost statements to units. Do you want to continue uploading and assigning?',
      de: 'Durch Hochladen der Gesamtabrechnung (PDF), können Sie die Zuweisung der Einzelabrechnungen auf die jeweiligen Einheiten durchführen. Möchten Sie mit dem Hochladen und Zuweisen fortfahren?',
    },
  },
  addModal: {
    button: {
      en: 'Create WKA',
      de: 'WKA erstellen',
    },
    property: {
      en: 'Property',
      de: 'Objekt',
    },
    year: {
      en: 'Year',
      de: 'Jahr',
    },
  },
};
